import React from 'react';
import logo from './logo.svg';
import './App.css';
// @ts-ignore
import { DM3 , DM3Configuration} from '@dm3-org/dm3-messenger-widget';

function App() {
  const props: DM3Configuration = { 
    defaultContact: 'help.dm3.eth',
    userEnsSubdomain: process.env.REACT_APP_USER_ENS_SUBDOMAIN as string,
    addressEnsSubdomain: process.env.REACT_APP_ADDR_ENS_SUBDOMAIN as string,
    resolverBackendUrl: process.env.REACT_APP_RESOLVER_BACKEND as string,
    profileBaseUrl: process.env.REACT_APP_PROFILE_BASE_URL as string,
    defaultDeliveryService: process.env
        .REACT_APP_DEFAULT_DELIVERY_SERVICE as string,
    backendUrl: process.env.REACT_APP_BACKEND as string,
    chainId: process.env.REACT_APP_CHAIN_ID as string,
    resolverAddress: process.env.REACT_APP_RESOLVER_ADDR as string,
    defaultServiceUrl: process.env.REACT_APP_DEFAULT_SERVICE as string,
    ethereumProvider: process.env.REACT_APP_MAINNET_PROVIDER_RPC as string,
    walletConnectProjectId: process.env
        .REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
    showAlways: true,
    hideFunction: undefined, // OPTINAL PARAMETER : 'attachments,edit,delete' or undefined
    showContacts: true, // true for all contacts / false for default contact
    theme: undefined, // OPTINAL PARAMETER : undefined/themeColors
    signInImage: undefined, // OPTINAL PARAMETER : string URL of image
};
  return (
    <div className="App">
     <div className="dm3-container">
   <DM3 {...props} />
</div>
    </div>
  );
}

export default App;
